module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/chip/code/gpt2/static-site/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/home/chip/code/gpt2/static-site/node_modules/@marinda/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Real recipes, AI created stories","short_name":"AI Food Blog","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"/home/chip/code/gpt2/static-site/node_modules/@marinda/gatsby-theme-recipes/src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5ba6f3df7db6e689317d05b8a24925c5"},
    },{
      plugin: require('../node_modules/@marinda/gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[],"title":"Real recipes, AI created stories","shortTitle":"AI Food Blog"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173985037-1","head":true},
    }]
