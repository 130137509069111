/** @jsx jsx */
import { jsx } from "@emotion/core";
// import { Styled } from "theme-ui";
// import { Link } from "gatsby";
// import Img from "gatsby-image";

const GptRecipe = ({ recipe: { directions, ingredients, intro } }) => {
  return (
      <div>
        <p>{intro}</p>
        <h6>Ingredients</h6>
        <ul>
            {ingredients.map(i => <li dangerouslySetInnerHTML={{__html: i}}></li>)}
        </ul>
        <h6>Directions</h6>
        <ol>
            {directions.map(d => <li>{d}</li>)}
        </ol>
      </div>
  );
};

export default GptRecipe;